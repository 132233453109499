<template>
  <div class="write-essay q-item">
    <div :class="('ncard-top s-bt' + rtlAndPadding).trim()">
      <div>
        <i
          class="text-contrast raw-icon el-icon-fa-pencil"
        ></i>
        <span class="card-title"
          >{{ questionTypeforView }} - {{ wordCount }}
          {{ words }}</span
        >
      </div>
      <div class="right-timer">
        <top-timer
          @stop="goNext"
          :key="2"
          :duration="1"
          :max="values.time || 1200"
        />
      </div>
    </div>
    <div class="ncard-body">
      <p :class="checkRTL.trim()">{{ values.question }}</p>
    </div>
    <div class="ncard-input">
      <el-input
        type="textarea"
        :autosize="{ minRows: 10 }"
        placeholder="Your Response"
        v-model="input"
        @input="updateAnswer"
        label="Your Response"
        :maxlength="maxWord"
        autofocus
        :class="checkRTL.trim()"
      ></el-input>
    </div>
    <transition name="slide">
      <div class="tooltip" v-if="validation">
        <span
          class="tooltiptext"
          :class="{
            tooltiptextGrey: vtextcolor,
            tooltiptextRed: !vtextcolor,
          }"
          >{{ validateWords }} - {{ strVMessage }}</span
        >
      </div>
    </transition>
    <br />
  </div>
</template>

<script>
import TopTimer from '../online-exam/topTimer';
import UtilsText from '../../../../shared/utils-text';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'writeEssay',
  components: {
    TopTimer,
  },
  data() {
    return {
      input: '',
      wordCount: 0,
      validation: false,
      vtextcolor: false,
      maxWord: null,
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
    values() {
      return this.currentQuestion.data;
    },
    words() {
      return i18n('entities.onlineExam.word');
    },
    strVMessage() {
      let message;
      if (this.wordCount <= 200)
        message = i18n(
          'entities.onlineExam.validations.writeEssay.lessWordsMessage',
        );
      else if (this.wordCount > 299)
        message = i18n(
          'entities.onlineExam.validations.writeEssay.tooWordsMessage',
        );
      return message;
    },
    validateWords() {
      let value;
      if (this.wordCount < 200)
        value = 200 - this.wordCount;
      else if (this.wordCount > 299)
        value = this.wordCount - 300;
      return value;
    },
  },
  mounted() {
    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    updateAnswer() {
      this.wordCount = UtilsText.wordCount(this.input);
      this.setCurrentAnswer({
        input: this.input,
      });
      if (this.wordCount > 300) {
        this.maxWord = this.input.length;
      } else {
        this.maxWord = null;
      }
      if (this.wordCount < 200 || this.wordCount > 300) {
        if (this.wordCount > 5) {
          this.validation = true;
          if (!this.vtextcolor) {
            setTimeout(() => {
              this.vtextcolor = true;
            }, 5000);
          }
        }
      } else {
        this.validation = false;
        if (this.vtextcolor) {
          this.vtextcolor = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.ncard-top.s-bt {
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}
.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ncard-input {
  border-top: 1px solid #efefef;
  padding-top: 1rem;
}
</style>
