<template>
  <div class="q-item">
    <audio-listener
      v-if="localstep === 0"
      key="repeatSentence0"
      :src="values.src"
      :initialTime="values.initialTime || 5"
      @onAudioListenerEnded="changeState"
    />
    <audio-recorder
      v-if="localstep === 1"
      key="repeatSentence1"
      :recordTime="values.recordTime || 8"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import audioListener from '../online-exam/audioListener';
import audioRecorder from '../online-exam/audioRecorder';

export default {
  name: 'repeatSentence',
  components: {
    audioRecorder,
    audioListener,
  },
  data() {
    return {
      localstep: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
  methods: {
    changeState() {
      this.localstep = 1;
    },
  },
};
</script>
