<template>
  <div class="written-text q-item">
    <div class="ncard-top s-bt">
      <div class="right-timer">
        <top-timer
          @stop="goNext"
          :key="2"
          :duration="1"
          :max="values.time || 600"
        />
      </div>
      <div :class="rtlAndPadding.trim()">
        <i
          class="text-contrast raw-icon el-icon-fa-pencil"
        ></i>
        <span class="card-title"
          >{{ questionTypeforView }} - {{ wordCount }}
          {{ words }}</span
        >
      </div>
    </div>
    <div :class="('ncard-body' + checkRTL).trim()">
      <p
        v-for="(p, index) in nl2p(values.question)"
        :key="index"
      >
        {{ p }}
      </p>
    </div>
    <div class="ncard-input">
      <el-input
        type="textarea"
        :autosize="{ minRows: 4 }"
        placeholder="Your Summary"
        v-model="input"
        @input="updateAnswer"
        autofocus
        :class="checkRTL.trim()"
      ></el-input>
    </div>

    <transition name="slide">
      <div class="tooltip" v-if="validation">
        <span class="tooltiptext"
          >{{ validateWords }} - {{ strVMessage }}</span
        >
      </div>
    </transition>
  </div>
</template>

<script>
import TopTimer from '../online-exam/topTimer';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UtilsText from '../../../../shared/utils-text';
import { i18n } from '@/i18n';

export default {
  name: 'summarizeWrittenText',
  components: {
    TopTimer,
  },
  data() {
    return {
      input: '',
      wordCount: 0,
      validation: false,
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
    values() {
      return this.currentQuestion.data;
    },
    words() {
      return i18n('entities.onlineExam.word');
    },
    strVMessage() {
      let message = i18n(
        'entities.onlineExam.validations.summarizeWrittenText.tooWordsMessage',
      );
      return message;
    },
    validateWords() {
      return this.wordCount - 75;
    },
  },
  mounted() {
    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    // yeni satırlara göre split yapar
    nl2p(str) {
      return str.split('\n');
    },
    updateAnswer() {
      this.wordCount = UtilsText.wordCount(this.input);
      this.setCurrentAnswer({
        input: this.input,
      });
      if (this.wordCount > 75) {
        this.validation = true;
      } else {
        this.validation = false;
      }
    },
  },
};
</script>

<style scoped>
.ncard-top.s-bt {
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}
.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  margin: 0.5rem 0;
  font-size: 15px;
  text-indent: 1rem;
  border-radius: 0.5rem;
  /*box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),*/
  /*  0 3px 1px -2px rgba(0, 0, 0, 0.2),*/
  /*  0 1px 5px 0 rgba(0, 0, 0, 0.12);*/
}

.rtl > p {
  direction: rtl;
}

.ncard-input {
  border-top: 1px solid #efefef;
  padding-top: 1rem;
}
</style>
