<template>
  <div class="q-item">
    <audio-listener
      v-if="localstep === 0"
      key="answerShortQuestion0"
      :src="values.src"
      @onAudioListenerEnded="changeState"
    />
    <audio-recorder
      v-if="localstep === 1"
      key="answerShortQuestion1"
      :initialTime="values.initialTime || 2"
      :recordTime="values.recordTime || 7"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import audioListener from '../online-exam/audioListener';
import audioRecorder from '../online-exam/audioRecorder';

export default {
  name: 'answerShortQuestion',
  components: {
    audioRecorder,
    audioListener,
  },
  data() {
    return {
      localstep: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
  methods: {
    changeState() {
      this.localstep = 1;
    },
  },
};
</script>

<style>
h5.subtitle {
  margin: 1.5rem 8rem 1.2rem 8rem;
}
</style>
