<template>
  <div class="q-item">
    <audio-recorder
      key="readAloud"
      :initialTime="values.initialTime || 20"
      :recordTime="values.readingTime || 40"
    />
    <p :class="('q-desc' + checkRTL).trim()">
      {{ values.question }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import audioRecorder from '../online-exam/audioRecorder';
export default {
  name: 'readAloud',
  components: {
    audioRecorder,
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
};
</script>

<style scoped></style>
