<template>
  <div class="q-item">
    <div class="ncard-body">
      <audio-listener :src="values.src" />
    </div>
    <div class="ncard-input">
      <div
        :class="
          ('fill-wrap' + checkTextRight + checkRTL).trim()
        "
      >
        <span v-for="(item, index) in items" :key="index">
          {{ item }}
          <span
            v-if="index < items.length - 1"
            class="input-fill-wrap"
          >
            <input
              :name="index"
              placeholder="Fill"
              class="el-input__inner inlined"
              type="text"
              @input="onChange"
              autocomplete="off"
            />
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import audioListener from '../online-exam/audioListener';
import UtilsText from '@/shared/utils-text';

let inputs = [];

export default {
  name: 'writeInTheBlanks',
  data() {
    return {
      items: [],
    };
  },
  components: {
    audioListener,
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      checkTextRight: 'onlineExam/main/checkTextRight',
      currentLanguage: 'onlineExam/main/currentLanguage',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
  mounted() {
    const values = this.currentQuestion.data;
    this.items = values.question.split('$$');
    inputs = new Array(this.items.length - 1).fill('');
    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    //inputs
    updateAnswer() {
      //console.log(inputs);
      this.setCurrentAnswer({
        inputs: inputs,
      });
    },
    onChange(evt) {
      const index = parseInt(evt.target.name);
      inputs[index] = this.toLower(evt.target.value.trim());
      this.updateAnswer();
    },
    toLower(option) {
      return UtilsText.LowerCase(
        option,
        this.currentLanguage,
      );
    },
  },
};
</script>

<style scoped>
.ncard-top.s-bt {
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}

.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.ncard-input {
  border-top: 1px solid #efefef;
  padding-top: 1rem;
}

.select-fill-wrap {
  display: inline-block;
  position: relative;
  padding: 0.25rem;
  border: 1px solid #a51e36;
  border-radius: 0.25rem;
  line-height: 1.25;
  background-color: #f4f4f4;
}

.select-fill-wrap:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #a51e36;
  right: 5px;
  top: 12px;
}

.select-fill-wrap > select {
  color: black;
  font-weight: 300;
  background-color: transparent;
  padding-right: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  font-size: 1rem;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
  -webkit-transition: 0.25s all ease;
  transition: 0.25s all ease;
  position: relative;
}
</style>

<style>
.fill-wrap {
  line-height: 2;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.el-input__inner.inlined {
  display: inline;
  height: 30px;
  width: auto;
  margin-bottom: 5px;
}
</style>
