<template>
  <div>
    <p class="sm">{{ message }}</p>
    <radial-progress-bar
      :diameter="75"
      :completed-steps="counter"
      :total-steps="duration"
      startColor="#f4f4f4"
      stopColor="#f4f4f4"
      innerStrokeColor="#8f1a2f"
      :strokeWidth="4"
    >
      <p>
        {{ sayacFiltered }}
      </p>
    </radial-progress-bar>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';
export default {
  name: 'radialTimer',
  data() {
    return {
      counter: 0,
    };
  },
  components: {
    RadialProgressBar,
  },
  computed: {
    sayac() {
      if (this.counter >= this.duration) {
        return 0;
      }
      return this.duration - this.counter;
    },
    sayacFiltered() {
      if (this.inMinutes) {
        return this.$options.filters.formatDurationFilter(
          this.sayac,
        );
      } else {
        return this.sayac;
      }
    },
  },
  timers: {
    log: {
      time: 1000,
      autostart: true,
      repeat: true,
    },
  },
  methods: {
    log() {
      this.counter++;
      if (this.counter >= this.duration) {
        this.$emit('stop');
        this.$timer.stop('log');
      }
    },
  },
  props: {
    duration: Number,
    message: String,
    inMinutes: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.radial-progress-container {
  margin: auto;
}

p.sm {
  margin: 0.1rem 0;
}
</style>
