<template>
  <div class="written-text q-item">
    <div class="ncard-body mt-minus">
      <audio-listener-base :src="values.src" />
      <h4 :class="checkRTL.trim()">
        {{ values.question }}
      </h4>
    </div>
    <!--    <div class="ncard-top mt-zero">-->
    <!--      <div :class="rtlAndPadding.trim()">-->
    <!--        <i class="text-contrast raw-icon el-icon-fa-pencil"></i>-->
    <!--        <span class="card-title">{{questionTypeforView}}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="ncard-input">
      <div
        :class="('ncard-options' + checkTextRight).trim()"
      >
        <el-checkbox-group
          @change="updateAnswer"
          v-model="inputs"
        >
          <el-row>
            <div
              class="ncard-option"
              v-for="(option, index) in values.options"
              :key="index"
            >
              <el-checkbox-button
                class="w100"
                :label="option"
              >
                <span class="checkbox-btn-text"> {{ option }} </span>
              </el-checkbox-button>
            </div>
          </el-row>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import audioListenerBase from '../online-exam/audioListenerBase';

export default {
  name: 'multipleAnswer',
  data() {
    return {
      inputs: [],
    };
  },
  components: {
    audioListenerBase,
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      checkRTL: 'onlineExam/main/checkRTL',
      checkTextRight: 'onlineExam/main/checkTextRight',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
  mounted() {
    this.setShowNext(true);
  },
  methods: {
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    updateAnswer() {
      this.setCurrentAnswer({
        inputs: this.inputs,
      });
    },
  },
};
</script>

<style scoped>
.checkbox-btn-text {
  white-space: normal;
  line-height: 18px;
}
.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ncard-input {
  padding-top: 1rem;
}
.ncard-options .el-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.ncard-option > label {
  min-width: 50%;
  margin: 0 auto;
}

.ncard-options .el-col > label > span {
  width: 100%;
  text-align: left;
  color: #3a3d3c;
  font-size: 0.95rem;
  white-space: unset !important;
  line-height: 1.5;
}

.ncard-option {
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
}
.mt-zero {
  margin-top: 0;
}
.mt-minus {
  margin-top: -3rem;
}
</style>

<style>
h5.subtitle {
  margin: 1.5rem 0.75rem 1.2rem 0.75rem;
}
.w100 > input {
  width: 100%;
}
.w100 > span {
  width: 100%;
}
</style>
