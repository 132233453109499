<template>
    <div class="roof">
        <div v-if="values.srcImg" class="left-side">
            <img :src="values.srcImg" class="image-max-h" alt/>
        </div>
        <div class="right-side">
            <audio-listener
                    v-if="localstep === 0"
                    key="repeatSentence0"
                    :src="values.src"
                    @onAudioListenerEnded="changeState"
                    :width-type="getAudioWidthType"
            />
            <audio-recorder
                    v-if="localstep === 1"
                    key="repeatSentence1"
                    :initialTime="values.initialTime || 10"
                    :recordTime="values.recordTime || 40"
                    width-type=":getAudioWidthType"
            />
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    import audioListener from '../online-exam/audioListener';
    import audioRecorder from '../online-exam/audioRecorder';

    export default {
        name: 'retellLecture',
        components: {
            audioRecorder,
            audioListener,
        },
        data() {
            return {
                localstep: 0,
            };
        },
        computed: {
            ...mapGetters({
                currentQuestion: 'onlineExam/main/currentQuestion',
            }),
            getAudioWidthType() {
                if (this.values.srcImg) {
                    return "n"
                }
                return "w"
            },
            values() {
                return this.currentQuestion.data;
            },
        },
        methods: {
            changeState() {
                this.localstep = 1;
            },
        },
    };
</script>

<style>
    h5.subtitle {
        margin: 1.5rem 8rem 1.2rem 8rem;
    }
</style>

<style scoped>
    .roof {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .left-side {
        margin-right: 3rem;
    }

    .image-max-h {
        max-height: 475px;
        width: 100%;
    }
</style>
