<template>
  <div class="written-text q-item">
    <div class="ncard-top">
      <div :class="rtlAndPadding.trim()">
        <i
          class="text-contrast raw-icon el-icon-fa-pencil"
        ></i>
        <span class="card-title">{{
          questionTypeforView
        }}</span>
      </div>
    </div>
    <div class="ncard-body">
      <p :class="checkRTL.trim()">
        {{ values.question }}
      </p>
      <h4 :class="checkRTL.trim()">
        {{ values.subtitle }}
      </h4>
    </div>
    <div class="ncard-input">
      <div class="ncard-options">
        <el-checkbox-group
          @change="updateAnswer"
          v-model="inputs"
        >
          <el-row
            :class="
              checkRTL.trim() + ' ' + checkTextRight.trim()
            "
          >
            <div
              class="optionz"
              v-for="(option, index) in values.options"
              :key="index"
            >
              <el-checkbox-button
                class="w100"
                :label="option"
              >
                <span class="checkbox-btn-text"> {{ index + 1 + '- ' + option }} </span>
              </el-checkbox-button>
            </div>
          </el-row>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'multipleOption',
  data() {
    return {
      inputs: [],
    };
  },
  computed: {
    ...mapGetters({
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkTextRight: 'onlineExam/main/checkTextRight',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
  mounted() {
    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    updateAnswer() {
      this.setCurrentAnswer({
        inputs: this.inputs,
      });
    },
  },
};
</script>

<style scoped>
.checkbox-btn-text {
  white-space: normal;
  line-height: 18px;
}
.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ncard-input {
  border-top: 0px;
  border-radius: 0.5rem;
  padding-top: 0.2rem;
}
.ncard-options {
  text-align: left;
}

.ncard-options .el-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ncard-options .el-col {
  margin-bottom: 0.5rem;
  margin-left: 1rem;
}
.ncard-options .el-col > label {
  /*width: 100%;*/
  /*margin-left: 0.5rem;*/
}

.optionz {
  margin-bottom: 0.5rem;
  width: 100%;
  display: flex;
}

h4 {
  font-size: 1rem;
  font-weight: 600;
}
.optionz > label {
  min-width: 50%;
  margin: 0 auto;
}

.optionz span.el-checkbox-button__inner {
  width: 100%;
  text-align: left;
}
</style>

<style>
.w100 > input {
  width: 100%;
}
.w100 > span {
  width: 100%;
}
</style>
