<template>
  <div class="q-item">
    <div class="ncard-body mt-minus">
      <audio-listener-base :src="values.src" />
    </div>
    <div class="ncard-input">
      <div class="ncard-options options-radio">
        <el-radio-group v-model="input">
          <el-row :class="('colz' + checkRTL).trim()">
            <div
              class="ncard-option"
              v-for="(option, index) in values.options"
              :key="index"
            >
              <el-radio
                :label="option"
                border
                @change="updateAnswer"
                >{{ ' ' + option + ' ' }}</el-radio
              >
            </div>
          </el-row>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import audioListenerBase from '../online-exam/audioListenerBase';

export default {
  name: 'highlightCorrectSummary',
  components: {
    audioListenerBase,
  },
  data() {
    return {
      input: '',
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      checkTextRight: 'onlineExam/main/checkTextRight',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
  mounted() {
    this.setShowNext(true);
  },
  methods: {
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    updateAnswer() {
      this.setCurrentAnswer({
        input: this.input,
      });
    },
  },
};
</script>

<style scoped>
.mt-zero {
  margin-top: 0;
}
.mt-minus {
  margin-top: -3rem;
}
</style>

<style>
h5.subtitle {
  margin: 1.5rem 8rem 1.2rem 8rem;
}
.ncard-options.options-radio > div {
  width: 100%;
}

.ncard-options.options-radio > div .el-radio {
  white-space: normal;
  overflow: auto;
  height: auto;
  padding-bottom: 10px;
  line-height: 1.5;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ncard-options.options-radio label > span {
  color: black;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 400;
}

.ncard-option {
  min-width: 50%;
  margin: 0 auto;
}

.el-row.colz {
  display: flex;
  flex-direction: column;
}
</style>
