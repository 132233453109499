<template>
  <div class="reorder q-item">
    <div class="ncard-top">
      <div :class="rtlAndPadding.trim()">
        <i
          class="text-contrast raw-icon el-icon-fa-pencil"
        ></i>
        <span class="card-title">{{
          questionTypeforView
        }}</span>
      </div>
    </div>

    <div class="ncard-input">
      <div
        :class="
          'ncard-options' + checkTextRight + checkRTL.trim()
        "
      >
        <Container @drop="onDrop">
          <Draggable
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="draggable-item">
              {{ item }}
            </div>
          </Draggable>
        </Container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Container, Draggable } from 'vue-smooth-dnd';

export default {
  name: 'reorderParagraph',
  components: {
    Draggable,
    Container,
  },
  data() {
    return {
      list: [],
      originOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      checkTextRight: 'onlineExam/main/checkTextRight',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
  mounted() {
    if (this.list.length === 0) {
      this.list = this.currentQuestion.data.options;
    }

    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    onDrop(dropResult) {
      this.list = this.applyDrag(this.list, dropResult);
      this.updateAnswer();
    },
    compare() {
      let list = [];
      for (let i = 0; i < this.list.length; i++) {
        const elem = this.list[i];
        list.push(this.values.options.indexOf(elem));
      }
      return list;
    },
    updateAnswer() {
      this.setCurrentAnswer({
        inputs: this.list, // text gönderilir.
      });
    },
    applyDrag(arr, dragResult) {
      const {
        removedIndex,
        addedIndex,
        payload,
      } = dragResult;
      if (removedIndex === null && addedIndex === null)
        return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },
  },
};
</script>

<style scoped>
.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ncard-input {
  padding-top: 1rem;
}
.ncard-options {
}

.ncard-options > div > div {
  padding-bottom: 8px;
  border-bottom: 1px solid #efefef;
  padding-top: 8px;
}

.ncard-options .el-col {
  margin-bottom: 0.5rem;
}
.ncard-options .el-col > label {
  width: 100%;
}

.draggable-item {
  cursor: move;
  margin: 0.5rem 0;
  line-height: 1.5;
}
.q-wrap h5.subtitle {
  font-weight: 600;
  font-size: 1rem;
}
</style>

<style>
.smooth-dnd-ghost.vertical.smooth-dnd-draggable-wrapper {
  background: #f4f4f4;
  border: 2px solid #efefef;
  border-radius: 0.5rem;
  padding: 0.5rem;
  /*cursor: move!important; calismiyor?*/
}
</style>
