<template>
    <div :class="getStyle">
        <div class="reclist-top">
            <i
                    class="text-contrast raw-icon"
                    :class="titleIcon"
            ></i>
            <span class="reclist-title">{{ titleText }}</span>
        </div>
        <div v-if="localstep === 0" class="reclist-content">
            {{recordingWillBegin[0]}}
            <textTimer
                    @stop="startRecord"
                    :duration="initialTime"
            />&nbsp;{{recordingWillBegin[1]}}
        </div>
        <div v-if="localstep === 1" class="reclist-content">
            <radialTimer
                    @stop="goNextQuestion"
                    :duration="recordTime"
                    :message="message"
            />
        </div>
    </div>
</template>

<script>
    import textTimer from './textTimer';
    import radialTimer from '../../components/radialTimer';
    import {mapActions, mapMutations} from 'vuex';
    import {i18n} from '@/i18n';

    export default {
        name: 'audioRecorder',
        components: {
            textTimer,
            radialTimer,
        },
        props: {
            initialTime: {
                type: Number,
                default: 0,
            },
            recordTime: {
                type: Number,
                required: true,
            },
            widthType: {
                type: String,
                default: "w"
            }
        },
        data() {
            return {
                localstep: 0,
                titleText: i18n("entities.onlineExam.audioRecorder.waitText"),
                titleIcon: 'el-icon-fa-pause',
            };
        },
        methods: {
            ...mapActions({
                record: 'onlineExam/recorder/record',
                goNext: 'onlineExam/main/goNext',
                ...mapMutations({
                    setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
                }),
                setNextVisible() {
                    this.setShowNext(true);
                },
            }),
            startRecord() {
                this.localstep = 1;
                this.$emit('onStart');
                this.setNextVisible();
                this.titleText = i18n("entities.onlineExam.audioRecorder.message");
                this.titleIcon = 'el-icon-fa-microphone';
                this.record();
            },
            goNextQuestion() {
                this.goNext();
            },
        },
        computed: {
            recordingWillBegin() {
                return i18n("entities.onlineExam.audioRecorder.recordingWillBegin").split('$textTimer$');
            },
            message() {
                return i18n("entities.onlineExam.audioRecorder.message");
            },
            getStyle() {
                if (this.widthType === "w") {
                    return "audio-reclist ar-wide"
                }
                return "audio-reclist ar-narrow"
            }
        },
        watch: {
            '$store.state.onlineExam.main.currentQuestion'() {
                this.localstep = 0;
            },
        },
    };
</script>

<style scoped>
    .reclist-content {
        padding: 0.9375rem 1.875rem;
    }
</style>

<style>
    .audio-reclist {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        margin: 0 auto 30px auto;
    }

    .ar-wide {
        width: 400px;
    }

    .ar-narrow {
        width: 255px;
    }

    .reclist-top {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
        0 13px 24px -11px rgba(76, 175, 80, 0.6);
        padding: 1rem 15px;
        margin-left: 15px;
        color: white;
        border-radius: 3px;
        margin-top: -30px !important;
        margin-right: 15px;
        background: linear-gradient(60deg, #66bb6a, #388e3c);
    }

    span.reclist-title {
        margin-left: 0.5rem;
        font-weight: 600;
    }

    .audio-reclist.full {
        width: 100%;
    }
</style>
