<template>
  <div class="written-text q-item">
    <div class="ncard-body mt-minus">
      <audio-listener-base
        :src="values.src"
        @onAudioListenerEnded="
          () => {
            startTopTimer = true;
          }
        "
      />
    </div>

    <div class="ncard-input">
      <div class="ncard-top s-bt">
        <div :class="rtlAndPadding.trim()">
          <i
            class="text-contrast raw-icon el-icon-fa-pencil"
          ></i>
          <span class="card-title">
            {{ questionTypeforView }} - {{ wordCount }}
            {{ words }}
          </span>
        </div>
        <div class="right-timer">
          <top-timer
            v-if="startTopTimer"
            @stop="goNext"
            :key="2"
            :duration="1"
            :max="values.time || 600"
          />
        </div>
      </div>
      <el-input
        type="textarea"
        :autosize="{ minRows: 4 }"
        placeholder="Your Summary"
        v-model="input"
        @input="updateAnswer"
        :maxlength="maxWord"
        autofocus
        :class="checkRTL"
      ></el-input>
    </div>

    <transition name="slide">
      <div class="tooltip" v-if="validation">
        <span
          class="tooltiptext"
          :class="{
            tooltiptextGrey: vtextcolor,
            tooltiptextRed: !vtextcolor,
          }"
          >{{ validateWords }} - {{ strVMessage }}</span
        >
      </div>
    </transition>
  </div>
</template>

<script>
import TopTimer from '../online-exam/topTimer';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UtilsText from '../../../../shared/utils-text';
import audioListenerBase from '../online-exam/audioListenerBase';
import { i18n } from '@/i18n';

export default {
  name: 'summarizeSpokenText',
  components: {
    TopTimer,
    audioListenerBase,
  },
  data() {
    return {
      input: '',
      startTopTimer: false,
      wordCount: 0,
      validation: false,
      vtextcolor: false,
      maxWord: null,
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
    values() {
      return this.currentQuestion.data;
    },
    words() {
      return i18n('entities.onlineExam.word');
    },
    strVMessage() {
      let message;
      if (this.wordCount <= 50)
        message = i18n(
          'entities.onlineExam.validations.summarizeSpokenText.lessWordsMessage',
        );
      else if (this.wordCount > 70)
        message = i18n(
          'entities.onlineExam.validations.summarizeSpokenText.tooWordsMessage',
        );
      return message;
    },
    validateWords() {
      let value;
      if (this.wordCount < 50) value = 50 - this.wordCount;
      else if (this.wordCount > 70)
        value = this.wordCount - 70;
      return value;
    },
  },
  mounted() {
    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    updateAnswer() {
      this.wordCount = UtilsText.wordCount(this.input);
      this.setCurrentAnswer({
        input: this.input,
      });
      if (this.wordCount > 70) {
        this.maxWord = this.input.length;
      } else {
        this.maxWord = null;
      }
      if (this.wordCount < 50 || this.wordCount > 70) {
        if (this.wordCount > 5) {
          this.validation = true;
          if (!this.vtextcolor) {
            setTimeout(() => {
              this.vtextcolor = true;
            }, 5000);
          }
        }
      } else {
        this.validation = false;
        if (this.vtextcolor) {
          this.vtextcolor = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.ncard-top.s-bt {
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}

.ncard-body {
  min-height: 140px;
}

.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ncard-input {
  /*border-top: 1px solid #efefef;*/
  padding-top: 1rem;
}
.ncard-top.s-bt + div {
  margin-top: 2rem;
}

.mt-minus {
  margin-top: -3rem;
}
</style>

<style>
h5.subtitle {
  margin: 1.5rem 0.75rem 1.2rem 0.75rem;
}
</style>
