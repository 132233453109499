import { render, staticRenderFns } from "./readAloud.vue?vue&type=template&id=bb6e9b00&scoped=true&"
import script from "./readAloud.vue?vue&type=script&lang=js&"
export * from "./readAloud.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb6e9b00",
  null
  
)

export default component.exports