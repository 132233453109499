<template>
  <div class="written-text q-item">
    <div class="ncard-body mt-minus">
      <audio-listener-base :src="values.src" />
    </div>
    <div class="ncard-top mt-zero">
      <div :class="rtlAndPadding.trim()">
        <i
          class="text-contrast raw-icon el-icon-fa-pencil"
        ></i>
        <span class="card-title">{{
          questionTypeforView
        }}</span>
      </div>
    </div>
    <div class="ncard-input">
      <el-input
        type="textarea"
        :autosize="{ minRows: 4 }"
        placeholder="Your Summary"
        v-model="input"
        @input="updateAnswer"
        :maxlength="maxWord"
        autofocus
        :class="(checkTextRight + checkRTL).trim()"
      ></el-input>
    </div>

    <transition name="slide">
      <div class="tooltip" v-if="validation">
        <span class="tooltiptext">{{ strVMessage }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import audioListenerBase from '../online-exam/audioListenerBase';
import UtilsText from '../../../../shared/utils-text';
import { i18n } from '@/i18n';

export default {
  name: 'writeFromTheDictation',
  components: {
    audioListenerBase,
  },
  data() {
    return {
      input: '',
      wordCount: 0,
      maxWord: null,
      validation: false,
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      checkTextRight: 'onlineExam/main/checkTextRight',
      currentLanguage: 'onlineExam/main/currentLanguage',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
    values() {
      return this.currentQuestion.data;
    },
    strVMessage() {
      let message = i18n(
        'entities.onlineExam.validations.writeFromTheDictation.wordlimitexceeded',
      );
      return message;
    },
  },
  mounted() {
    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    updateAnswer() {
      this.wordCount = UtilsText.wordCount(this.input);
      if (this.wordCount > 50) {
        this.maxWord = this.input.length;
        this.validation = true;
      } else {
        this.maxWord = null;
        this.validation = false;
      }
      this.setCurrentAnswer({
        input: this.toLower(this.input),
      });
    },
    toLower(option) {
      return UtilsText.LowerCase(
        option,
        this.currentLanguage,
      );
    },
  },
};
</script>

<style scoped>
.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ncard-input {
  padding-top: 1rem;
}
.mt-zero {
  margin-top: 0;
}
.mt-minus {
  margin-top: -3rem;
}
</style>
