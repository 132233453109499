<template>
  <div class="highlight-incorret q-item">
    <div class="ncard-body  mt-minus">
      <audio-listener-base :src="values.src" />
    </div>
    <div class="ncard-top mt-zero">
      <div :class="rtlAndPadding.trim()">
        <i
          class="text-contrast raw-icon el-icon-fa-pencil"
        ></i>
        <span class="card-title">{{
          questionTypeforView
        }}</span>
      </div>
    </div>
    <div class="ncard-input ">
      <div class="ncard-options">
        <div
          :class="
            (
              'hightlight-wrap' +
              checkTextRight +
              checkRTL
            ).trim()
          "
        >
          <span
            class="word"
            v-for="(text, index) in items"
            :key="index"
            @click="click"
            >{{ text }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import audioListenerBase from '../online-exam/audioListenerBase';

let selecteds = [];

export default {
  name: 'highlightIncorrectWords',

  components: {
    audioListenerBase,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      checkTextRight: 'onlineExam/main/checkTextRight',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
  mounted() {
    this.items = this.values.question.split(' ');
    selecteds = [];
    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    updateAnswer() {
      //console.log(selecteds);
      this.setCurrentAnswer({
        inputs: selecteds,
      });
    },
    click(evt) {
      const { target } = evt;
      if (target.classList.contains('active')) {
        target.classList.remove('active');
      } else {
        target.classList.add('active');
      }
      selecteds = [];
      const words = document.getElementsByClassName('word');
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        if (word.classList.contains('active')) {
          selecteds.push(word.textContent.trim());
        }
      }

      this.updateAnswer();
    },
  },
};
</script>

<style scoped>
.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ncard-input {
  padding-top: 1rem;
}
.ncard-options {
  text-align: left;
}

.ncard-options .el-col {
  margin-bottom: 0.5rem;
}
.ncard-options .el-col > label {
  width: 100%;
}
.hightlight-wrap {
  word-break: break-all;
}

span.word {
  margin-left: 0.05rem;
  margin-right: 0.05rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  line-height: 1.5;
  display: inline-block;
  transition: 0.25s all ease;
  cursor: pointer;
}
span.word:hover {
  background-color: #8f1a2f;
  color: white;
  border-radius: 0.4rem;
}
span.word.active {
  background-color: #8f1a2f;
  color: white;
  text-decoration: line-through;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 0.4rem;
}
span.word.active:hover {
  text-decoration: underline;
}
.mt-minus {
  margin-top: -3rem;
}

.mt-zero {
  margin-top: 0;
}
</style>

<style>
h5.subtitle {
  margin: 1rem 8rem 0.75rem 8rem;
}
</style>
