<template>
  <div>
    <question-card v-if="!isLoading">
      <component
        :key="questionType || 'baseQuestion'"
        v-bind:is="questionType"
      />
    </question-card>
    <i v-loading="isLoading"></i>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import readAloud from '../../components/question/readAloud';
import repeatSentence from '../../components/question/repeatSentence';
import describeImage from '../../components/question/describeImage';
import retellLecture from '../../components/question/retellLecture';
import answerShortQuestion from '../../components/question/answerShortQuestion';
import summarizeWrittenText from '../../components/question/summarizeWrittenText';
import writeEssay from '../../components/question/writeEssay';
import singleOption from '../../components/question/singleOption';
import multipleOption from '../../components/question/multipleOption';
import reorderParagraph from '../../components/question/reorderParagraph';
import fillInTheBlanks from '../../components/question/fillInTheBlanks';
import selectedFillInTheBlanks from '../../components/question/selectedFillInTheBlanks';
import highlightCorrectSummary from '../../components/question/highlightCorrectSummary';
import summarizeSpokenText from '../../components/question/summarizeSpokenText';
import multipleAnswer from '../../components/question/multipleAnswer';
import writeInTheBlanks from '../../components/question/writeInTheBlanks';
import singleAnswer from '../../components/question/singleAnswer';
import selectMissingWord from '../../components/question/selectMissingWord';
import highlightIncorrectWords from '../../components/question/highlightIncorrectWords';
import writeFromTheDictation from '../../components/question/writeFromTheDictation';

export default {
  name: 'question-base',
  components: {
    readAloud,
    repeatSentence,
    describeImage,
    retellLecture,
    answerShortQuestion,
    summarizeWrittenText,
    writeEssay,
    singleOption,
    multipleOption,
    reorderParagraph,
    fillInTheBlanks,
    selectedFillInTheBlanks,
    highlightCorrectSummary,
    summarizeSpokenText,
    multipleAnswer,
    writeInTheBlanks,
    singleAnswer,
    selectMissingWord,
    highlightIncorrectWords,
    writeFromTheDictation,
  },
  computed: {
    ...mapGetters({
      currentExam: 'onlineExam/main/currentExam',
      isLoading: 'onlineExam/main/isLoading',
      questionType: 'onlineExam/main/questionType',
    }),
  },
  beforeMount() {
    window.addEventListener(
      'beforeunload',
      this.preventNav,
    );
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener(
        'beforeunload',
        this.preventNav,
      );
    });
    //Sinavda kullanici onceki sayfaya gitmesin diye.
    for (let i = 0; i < 50; i++) {
      history.pushState({}, '');
    }
  },
  updated() {
    //Sinavda kullanici onceki sayfaya gitmesin diye.
    history.pushState({}, '');
  },
  methods: {
    ...mapActions({
      fetchCurrentExam: 'onlineExam/main/fetchCurrentExam',
    }),
    preventNav(event) {
      event.preventDefault();
      event.returnValue = '';
    },
  },
  async created() {
    const { id } = this.$route.params;

    try {
      await this.fetchCurrentExam(id);
    } catch (error) {
      //todo kullaniciyi bilgilendir
    }
  },
  mounted() {
    window.moveTo(0, 0);
    window.resizeTo(screen.width, screen.height);
    setTimeout(_activateFullscreen, 4000);
  },
};

document.addEventListener('onfullscreenchange', function() {
  setTimeout(_activateFullscreen, 1000);
});

document.addEventListener('keydown', function(e) {
  //
  if (e.code === 'Escape') {
    e.preventDefault();
    return false;
  }
});

window.onload = maxWindow;

function maxWindow() {
  window.moveTo(0, 0);
  if (document.all) {
    top.window.resizeTo(
      screen.availWidth,
      screen.availHeight,
    );
  } else if (document.layers || document.getElementById) {
    if (
      top.window.outerHeight < screen.availHeight ||
      top.window.outerWidth < screen.availWidth
    ) {
      top.window.outerHeight = screen.availHeight;
      top.window.outerWidth = screen.availWidth;
    }
  }
}

function _activateFullscreen() {
  let fullscreenElement = document.documentElement;
  if (fullscreenElement.mozRequestFullScreen) {
    /* Firefox */
    fullscreenElement.mozRequestFullScreen();
  } else if (fullscreenElement.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    fullscreenElement.webkitRequestFullscreen();
  } else if (fullscreenElement.msRequestFullscreen) {
    /* IE/Edge */
    fullscreenElement.msRequestFullscreen();
  }
}
</script>

<style scoped>
i {
  position: initial !important;
}
</style>

<style>
.ncard-top {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
  padding: 1rem 15px;
  margin-left: 15px;
  color: white;
  border-radius: 3px;
  margin-top: -30px;
  margin-right: 15px;
  background: linear-gradient(60deg, #66bb6a, #388e3c);
}

p.q-desc {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: justify;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f4f4f461;
  border: 1px solid #efefef;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}
</style>
