<template>
  <div class="audio-reclist-base">
    <div v-if="localstep === 0" class="reclist-content">
      {{listeningWillBegin[0]}}
      <textTimer
        @stop="startListening"
        :duration="initialTime || 5"
      />&nbsp;    {{listeningWillBegin[1]}}
    </div>
    <div v-if="localstep === 1" class="reclist-content">
      <av-circle
        :outline-width="0"
        :progress-width="5"
        :outline-meter-space="5"
        :playtime="true"
        :audio-controls="false"
        playtime-font="18px Monaco"
        :audio-src="src"
        :cors-anonym="true"
        barColor="['#FFFFFF', '#388e3c']"
        progressColor="#8f1a2f"
        playtimeColor="#a51e36"
      ></av-circle>
    </div>
    <div v-if="localstep === 2" class="reclist-content">
      <!--      <span>Finished</span>-->
    </div>
    <div
      v-if="hasListenError && localstep === 1"
      class="reclist-content"
    >
      <el-button
        class="btn-next mb"
        type="primary"
        round
        @click="listen"
        >Listen</el-button
      >
    </div>
  </div>
</template>

<script>
import textTimer from './textTimer';
import { mapMutations } from 'vuex';
import { i18nWL } from '@/i18n';
export default {
  name: 'audioListenerBase',
  components: {
    textTimer,
  },
  props: {
    initialTime: {
      type: Number,
      default: 3,
    },
    src: {
      type: String,
      required: true,
    },
    examLanguage: {
      type: String,
      default : 'en'
    }
  },
  data() {
    return {
      localstep: 0,
      audio: null,
      hasListenError: false,
    };
  },
  computed:{
    listeningWillBegin(){
      return i18nWL("entities.onlineExam.audioRecorder.listeningWillBegin", this.examLanguage).split('$textTimer$');
    },
  },
  updated() {
    const self = this;

    this.audio = document.getElementsByTagName('audio')[0];

    if (this.audio) {
      this.audio.onended = function() {
        self.$emit('onAudioListenerEnded');
        self.localstep = 2;
        self.destroyAudio();
      };
      // console.log("Props SRC:" ,this.src);
      // this.srcLocal = this.src;
      this.listen();
    }
  },
  methods: {
    ...mapMutations({
      destroyAudio: 'onlineExam/main/DESTROY_AUDIO',
    }),
    startListening() {
      this.$emit('onAudioListenerStart');
      this.localstep = 1;
    },
    async listen() {
      try {
        await this.audio.play();
        this.hasListenError = false;
      } catch (error) {
        //todo err message gostermek gerekir mi?
        //console.log('Listening Error', error.message);
        this.hasListenError = true;
      }
    },
  },
  watch: {
    '$store.state.onlineExam.main.currentQuestion'() {
      this.localstep = 0;
    },
  },
};
</script>

<style></style>

<style scoped>
.reclist-content {
  padding: 0.5rem 1.875rem;
  margin: auto 0;
}
.audio-reclist-base {
  margin: 0 auto;
}

.audio-reclist-base {
  /*box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),*/
  /*0 3px 1px -2px rgba(0, 0, 0, 0.2),*/
  /*0 1px 5px 0 rgba(0, 0, 0, 0.12);*/
  min-height: 120px;
  /*border-radius: 6px;*/
  display: flex;
  flex-direction: column;
}

.mb {
  margin-bottom: 0.5rem;
}
</style>
