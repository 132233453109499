<template>
  <div class="selected-fill-blanks q-item">
    <div class="ncard-top s-bt">
      <div :class="rtlAndPadding.trim()">
        <i
          class="text-contrast raw-icon el-icon-fa-pencil"
        ></i>
        <span class="card-title">{{
          questionTypeforView
        }}</span>
      </div>
    </div>
    <div class="ncard-body">
      <div
        :class="
          ('fill-wrap' + checkTextRight + checkRTL).trim()
        "
      >
        <span v-for="(item, index) in items" :key="index">
          {{ item }}
          <span
            v-if="index < items.length - 1"
            class="select-fill-wrap"
          >
            <select
              @change="onChange"
              :class="
                ('select-fill-item' + checkRTL).trim()
              "
              :name="index"
            >
              <option :value="-1">----</option>
              <option
                :value="option"
                v-for="(option, subindex) in options[index]"
                :key="index * 10 + subindex"
                >{{ option }}</option
              >
            </select>
          </span>
        </span>
      </div>
    </div>
    <div class="ncard-input"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
let selecteds = [];

export default {
  name: 'selectedFillInTheBlanks',
  data() {
    return {
      items: [],
      options: [],
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      checkTextRight: 'onlineExam/main/checkTextRight',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
  },
  mounted() {
    const values = this.currentQuestion.data;
    this.options = values.options;
    this.items = values.question.split('$$');
    selecteds = new Array(this.items.length - 1).fill('');
    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    updateAnswer() {
      this.setCurrentAnswer({
        inputs: selecteds,
      });
    },
    onChange(evt) {
      const index = parseInt(evt.target.name);
      selecteds[index] = evt.target.value;
      this.updateAnswer();
    },
  },
};
</script>

<style scoped>
.ncard-top.s-bt {
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  justify-content: center;
}

.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.ncard-input {
  border-top: 1px solid #efefef;
  padding-top: 1rem;
}

.select-fill-wrap {
  display: inline-block;
  position: relative;
  padding: 0.25rem;
  border: 1px solid #a51e36;
  border-radius: 0.25rem;
  line-height: 1.25;
  background-color: #f4f4f4;
}
.select-fill-wrap:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #a51e36;
  right: 5px;
  top: 12px;
}
.select-fill-wrap > select {
  color: black;
  font-weight: 300;
  background-color: transparent;
  padding-right: 1rem;
  padding-left: 0.2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  font-size: 1rem;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
  -webkit-transition: 0.25s all ease;
  transition: 0.25s all ease;
  position: relative;
}

span.el-checkbox-button__inner {
  white-space: normal;
  line-height: 1.4em;
}
</style>

<style>
.fill-wrap {
  line-height: 2.2;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
h5.subtitle {
  margin: 1rem 5rem 0.5rem 5rem;
}
</style>
