<template>
  <div class="written-text q-item">
    <div class="ncard-body mt-minus">
      <audio-listener-base :src="values.src" />
      <h4 :class="checkRTL.trim()">
        {{ values.question }}
      </h4>
    </div>
    <!--    <div class="ncard-top mt-zero">-->
    <!--      <div :class="rtlAndPadding.trim()">-->
    <!--        <i class="text-contrast raw-icon el-icon-fa-pencil"></i>-->
    <!--        <span class="card-title">{{questionTypeforView}}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="ncard-input">
      <div class="ncard-options">
        <el-row
          :class="
            checkRTL.trim() + ' ' + checkTextRight.trim()
          "
        >
          <el-col
            :span="24"
            v-for="(option, index) in values.options"
            :key="index"
            :class="rtlAndPadding.trim()"
          >
            <el-radio
              v-model="input"
              :label="option"
              border
              @change="updateAnswer"
              class="radio-element"
              > <span class="radio-element-text"> {{ ' ' + option + ' ' }} </span> </el-radio
            >
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import audioListenerBase from '../online-exam/audioListenerBase';

export default {
  name: 'singleAnswer',
  data() {
    return {
      input: '',
    };
  },
  components: {
    audioListenerBase,
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      checkTextRight: 'onlineExam/main/checkTextRight',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
  mounted() {
    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    updateAnswer() {
      this.setCurrentAnswer({
        input: this.input,
      });
    },
  },
};
</script>

<style scoped>
.radio-element {
  height: auto;
  padding-bottom: 10px !important;
}
.radio-element-text {
  white-space: normal;
  line-height: 18px;
}

.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ncard-input {
  padding-top: 1rem;
}
.ncard-options {
  text-align: left;
}

.ncard-options .el-col {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}
.ncard-options .el-col > label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.mt-zero {
  margin-top: 0;
}
.mt-minus {
  margin-top: -3rem;
}
.ncard-options label > span {
  font-weight: 600;
  color: #3a3d3c;
  font-size: 0.95rem;
}
</style>

<style>
h5.subtitle {
  margin: 1.5rem 0.75rem 1.2rem 0.75rem;
}
</style>
