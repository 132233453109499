<template>
  <div class="fill-blanks q-item">
    <div class="ncard-top s-bt">
      <div :class="rtlAndPadding.trim()">
        <i
          class="text-contrast raw-icon el-icon-fa-pencil"
        ></i>
        <span class="card-title">{{
          questionTypeforView
        }}</span>
      </div>
    </div>
    <div class="ncard-body">
      <div
        :class="
          ('fill-wrap' + checkTextRight + checkRTL).trim()
        "
      >
        <span v-for="(item, index) in items" :key="index">
          {{ item }}
          <span
            v-if="index < items.length - 1"
            class="fill-item"
            :data-id="index"
            @drop="drop"
            @dragover="allowDrop"
            @dragleave="onLeave"
            @click="show"
          ></span>
        </span>
      </div>
    </div>
    <div class="ncard-input">
      <div :class="('fill-options' + checkRTL).trim()">
        <button
          @dragstart="drag($event)"
          draggable="true"
          class="el-button el-button--default"
          v-for="(option, index) in options"
          :id="index"
          :key="index"
          v-show="option.show === true"
        >
          {{ option.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

let currentData = null;
let selecteds = [];

export default {
  name: 'fillInTheBlanks',
  data() {
    return {
      items: [],
      options: [],
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
      checkRTL: 'onlineExam/main/checkRTL',
      rtlAndPadding: 'onlineExam/main/rtlAndPadding',
      checkTextRight: 'onlineExam/main/checkTextRight',
      questionTypeforView:
        'onlineExam/main/questionTypeforView',
    }),
  },
  mounted() {
    const values = this.currentQuestion.data;
    this.options = values.options.map((option) => {
      return {
        text: option,
        show: true,
      };
    });
    this.items = values.question.split('$$');
    selecteds = new Array(this.items.length - 1).fill('');
    this.setShowNext(true);
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    ...mapMutations({
      setShowNext: 'onlineExam/main/SET_SHOW_NEXT',
      setCurrentAnswer:
        'onlineExam/main/SET_CURRENT_ANSWER',
    }),
    updateAnswer() {
      //console.log(selecteds);
      this.setCurrentAnswer({
        inputs: selecteds,
      });
    },

    drag(ev) {
      currentData = ev.target;
      //ev.dataTransfer.setData('text', ev.target.id);
      //ev.dataTransfer.setData('text', ev.target.textContent);
    },
    drop(ev) {
      ev.preventDefault();
      if (ev.target.hasAttribute('data-index')) {
        const index = ev.target.getAttribute('data-index');
        this.options[index].show = true;
      }
      this.hide(currentData.id);
      var currentId = parseInt(
        ev.target.getAttribute('data-id'),
      );
      selecteds[currentId] = currentData.textContent.trim();
      this.updateAnswer();
      ev.target.textContent = currentData.textContent;
      ev.target.classList.add('filled');
      ev.target.setAttribute('data-index', currentData.id);
      ev.target.classList.remove('drag-focus');
      currentData = null;
      //ev.target.appendChild(document.getElementById(data));
    },
    allowDrop(ev) {
      if (!ev.target.classList.contains('drag-focus')) {
        ev.target.classList.add('drag-focus');
      }
      ev.preventDefault();
    },
    hide(index) {
      this.options[index].show = false;
    },
    show(evt) {
      if (evt.target.classList.contains('filled')) {
        const index = parseInt(
          evt.target.getAttribute('data-index'),
        );
        this.options[index].show = true;
        const currentId = parseInt(
          evt.target.getAttribute('data-id'),
        );
        selecteds[currentId] = "";
        this.updateAnswer();
        this.reset(evt.target);
      }
    },
    reset(elem) {
      elem.setAttribute('class', 'fill-item');
      elem.removeAttribute('data-index');
      elem.textContent = '';
    },
    onLeave(evt) {
      if (evt.target.classList.contains('drag-focus')) {
        evt.target.classList.remove('drag-focus');
      }
    },
  },
};
</script>

<style scoped>
.ncard-top.s-bt {
  display: flex;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.ncard-body > p {
  text-align: justify;
  line-height: 1.5;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.ncard-input {
  border-top: 1px solid #a62038;
  padding-top: 1rem;
}

.draggable-item {
  cursor: move;
}

.fill-wrap {
  line-height: 2;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
</style>

<style>
span.fill-item {
  min-width: 100px;
  display: inline-flex;
  border-bottom: 1px solid #efefef;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  height: 25px;
}

span.fill-item.filled {
  background-color: #af253e;
  color: white;
  margin-bottom: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  cursor: pointer;
}
span.fill-item.filled:hover {
  text-decoration: line-through;
}
span.fill-item.drag-focus {
  border-color: #b22640;
  border-width: 2px;
}
.fill-options > button {
  font-weight: 600;
  color: black;
  font-size: 0.95rem;
}
</style>
