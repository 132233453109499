<template>
  <div>
    <div :class="getStyle" v-if="localstep === 0 || localstep === 1">
      <div class="reclist-top">
        <i class="text-contrast raw-icon" :class="titleIcon"></i>
        <span class="reclist-title">{{ titleText }}</span>
      </div>
      <audio-listener-base
        :initial-time="initialTime"
        :src="src"
        @onAudioListenerStart="handleStart"
        @onAudioListenerEnded="handleEnded"
        :examLanguage="examLanguage"
      />
    </div>
    <div class="audio-reclist" v-if="localstep === 2"></div>
  </div>
</template>

<script>
import audioListenerBase from './audioListenerBase';
import { mapGetters } from 'vuex';
import { i18nWL } from '@/i18n';
export default {
  name: 'audioListener',
  components: {
    audioListenerBase
  },
  props: {
    initialTime: {
      type: Number,
      default: 3
    },
    src: {
      type: String,
      required: true
    },
    widthType: {
      type: String,
      default: 'w'
    }
  },
  data() {
    return {
      localstep: 0,
      titleText: '',
      titleIcon: 'el-icon-fa-pause'
      //audio: null,
      //hasListenError: false,
    };
  },
  created() {
    this.titleText = i18nWL('entities.onlineExam.audioRecorder.waitText', this.examLanguage); 
  },
  methods: {
    handleStart() {
      this.localstep = 1;
      this.titleIcon = 'el-icon-fa-volume-up';
      this.titleText = i18nWL('entities.onlineExam.audioRecorder.listeningText', this.examLanguage);
    },
    handleEnded() {
      this.localstep = 2;
      this.$emit('onAudioListenerEnded');
    }
  },
  computed: {
    ...mapGetters({
      examLanguage: 'onlineExam/main/currentLanguage'
    }),
    getStyle() {
      if (this.widthType === 'w') {
        return 'audio-reclist ar-wide';
      }
      return 'audio-reclist ar-narrow';
    }
  },
  watch: {
    '$store.state.onlineExam.main.currentQuestion'() {
      this.localstep = 0;
    }
  }
};
</script>

<style scoped>
.reclist-content {
  padding: 0.9375rem 1.875rem;
}
</style>

<style>
.audio-reclist {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 30px auto;
}

.ar-wide {
  width: 400px;
}

.ar-narrow {
  width: 255px;
}

.reclist-top {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
  padding: 1rem 15px;
  margin-left: 15px;
  color: white;
  border-radius: 3px;
  margin-top: -30px !important;
  margin-right: 15px;
  background: linear-gradient(60deg, #66bb6a, #388e3c);
}

span.reclist-title {
  margin-left: 0.5rem;
  font-weight: 600;
}

.audio-reclist.full {
  width: 100%;
}
</style>
