<template>
  <div class="d-inline">{{ sayac }}</div>
</template>

<script>
export default {
  name: 'textTimer',
  data: () => {
    return {
      counter: 0,
    };
  },
  computed: {
    sayac() {
      if (this.counter === this.duration) {
        this.$timer.stop('log');
        this.$emit('stop');
        return 0;
      }
      return this.duration - this.counter;
    },
  },
  timers: {
    log: {
      time: 1000,
      autostart: true,
      repeat: true,
    },
  },
  methods: {
    log() {
      this.counter++;
    },
  },
  props: {
    duration: Number,
  },
};
</script>

<style>
.d-inline {
  display: inline;
}
</style>

<style scoped></style>
