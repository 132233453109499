<template>
  <div class="describe-image q-item">
    <el-row class="f-center">
      <el-col :span="16">
        <div class="grid-content">
          <img :src="values.src" class="image-max-h" alt />
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content">
          <audio-recorder
            key="describeImage"
            :initialTime="values.initialTime || 20"
            :recordTime="values.recordTime || 40"
            width-type="n"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import audioRecorder from '../online-exam/audioRecorder';

export default {
  name: 'describeImage',
  components: { audioRecorder },
  data() {
    return {
      localstep: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentQuestion: 'onlineExam/main/currentQuestion',
    }),
    values() {
      return this.currentQuestion.data;
    },
  },
  methods: {
    startRecord() {
      this.localstep = 1;
    },
  },
};
</script>

<style scoped>
.describe-image.ncard {
  width: 100% !important;
}

.image-max-h{
    max-height: 475px;
    width: 100%;
}

.ncard.full {
  width: 100%;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

h5.subtitle {
  margin: 1.5rem 6rem 1.2rem 6rem;
}
</style>

<style scoped>
.audio-reclist {
  margin: 0 auto 1rem 2rem;
}
</style>
